<template>
  <div class="search-records-results">
    <div class="search-results">
      <relaxed-search-disclaimer
        :meta="meta"
        :first-name="filters.first_name"
        :last-name="filters.surname"
      ></relaxed-search-disclaimer>
      <base-pagination-meta class="main-meta" :meta="meta" :title="title"></base-pagination-meta>
      <indexed-record-item v-for="item in list" :key="item.id" :item="item" :options="options"></indexed-record-item>
    </div>
    <base-pagination-meta class="bottom-meta" :meta="meta"></base-pagination-meta>
    <base-pagination :meta="meta" @onSwitchPage="onSwitchPage"></base-pagination>
  </div>
</template>

<script>
import BasePagination from '@common/elements/base-pagination';
import BasePaginationMeta from '@common/elements/base-pagination-meta';
import IndexedRecordItem from '@common/pages/searches/IndexedRecordItem';
import RelaxedSearchDisclaimer from '@common/pages/searches/RelaxedSearchDisclaimer';

export default {
  props: {
    filters: Object,
    meta: Object,
    options: Object,
    list: Array,
    title: String,
  },
  computed: {},
  methods: {
    onSwitchPage(page) {
      this.$emit('switch-page', page);
    },
  },
  components: {IndexedRecordItem, BasePagination, BasePaginationMeta, RelaxedSearchDisclaimer},
  name: 'IndexedRecordsResults',
};
</script>

<style scoped lang="scss">
.search-records-results {
  .base-pagination-meta {
    margin-bottom: 8px;
  }
}
</style>
